import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import resort2 from "../Assets/images/reso2.png";
import back from "../Assets/images/backHome.png";
import banner1 from "../Assets/AirbnbPic/Veiws/2.avif";
function EntertainmentGrid() {
  const data = [
    {
      img: resort2,
      head: "Fishing and Hunting Trips:",
      para: `  <p>We are calling all outdoor enthusiasts! Join our guided trips for thrilling Idaho hunting trips and fishing adventures. Get expert guidance, make friends, and return with tremendous stories. Our experienced guides know the best spots and techniques, maximizing your chances of a successful and memorable trip. It's an opportunity to learn new skills, appreciate nature, and bond with like-minded individuals who share your passions. Whether you're a seasoned angler or a first-time hunter, our trips are tailored to suit everyone, making it a rewarding and enjoyable experience.</p>`,
    },
    {
      img: banner1,
      head: "Couples Getaway:",
      para: `  <p>Escape to a romantic paradise and rekindle your love amidst nature's beauty with our Winter getaways in Lewiston. Enjoy privacy, and cook a beautiful dinner for two for a truly unforgettable experience. Our retreats for couples in Lewiston allow you and your partner to disconnect from daily distractions, providing quality time to focus on each other. The tranquil setting encourages open communication and sparks romance. With no interruptions, you can enjoy each other's company, creating memories to cherish long after the vacation ends. Don't forget to explore the scenic hikes near Grandview Getaway and take advantage of our romantic getaway packages in Lewiston for an extraordinary experience.</p>`,
    },
  ];
  return (
    <>
      <Box sx={style.main}>
        <Container sx={style.container}>
          <Box>
            {data.map((item, index) => {
              return (
                <Grid
                  container
                  justifyContent="space-between"
                  columnSpacing={4}
                  paddingY={"40px"}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    order={index % 2 === 0 ? 1 : 2}
                    marginY="2rem"
                  >
                    <Box sx={style.box}>
                      <a
                        style={{ display: "flex", alignSelf: "center" }}
                        href="https://www.airbnb.com/rooms/837244690525037242?guests=1&amp;adults=1&amp;s=66&amp;source=embed_widget"
                      >
                        <img
                          style={{
                            width: "100%",
                            // height: "432px",
                          }}
                          alt="oops"
                          src={item.img}
                        />
                      </a>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12} order={index % 2 === 0 ? 2 : 1}>
                    <Box sx={style.box1}>
                      <Typography sx={style.heading}>{item.head}</Typography>
                      <Typography
                        sx={style.Para}
                        dangerouslySetInnerHTML={{ __html: item.para }}
                      />
                      {/* <Box>
                        <a href="https://www.airbnb.com/rooms/837244690525037242?guests=1&amp;adults=1&amp;s=66&amp;source=embed_widget">
                          <Button variant="outlined" sx={style.btn}>
                            View Details
                          </Button>
                        </a>
                      </Box> */}
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default EntertainmentGrid;
const style = {
  main: {
    background: `url(${back}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",

    color: "white",
    paddingY: "3rem",
  },
  container: {
    maxWidth: { lg: "1200px", xl: "1500px" },
  },
  box: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignSelf: "center",
    justifyContent: { md: "start", xs: "center" },
  },
  box1: {
    width: "100%",
    height: "100%",

    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  heading: {
    color: "white",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Poppins",
  },
  Para: {
    color: "white",
    fontSize: "16px",
    fontFamily: "Poppins",
    width: "85%",
  },
  btn: {
    backgroundColor: "#0082CA",
    color: "white",
    paddingX: "15px",
    borderRadius: "5px",
    fontWeight: 600,
    fontSize: "1.1rem",
    paddingY: "10px",
    ":hover": {
      backgroundColor: "white",
      color: "#0082CA",
    },
  },
};
