import React from "react";
import { Container, Typography, List, ListItem, ListItemText } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import Slider from "../Home/Slider";

const pages = [
  {
    name: "Romantic Getaway Packages in Lewiston | Private Vacation Homes Lewiston",
    slug: "romantic-getaway-packages-lewiston",
  },
  {
    name: "Unforgettable Escapes: The Best Outdoor Getaways and Romantic Retreats in Idaho",
    slug: "outdoor-getaways-idaho",
  },
  {
    name: "Unforgettable Fly Fishing Adventures: Tips for Clearwater River Trips",
    slug: "unforgettable-fly-fishing-adventures-clearwater-river",
  },
  {
    name: "Cosmic Waters: Unveiling the Best Spots for Steelhead Fishing",
    slug: "cosmic-waters-steelhead-fishing-spots",
  },
  {
    name: "The Flip Side: What to Avoid While Salmon Fishing in Clearwater River",
    slug: "salmon-fishing-mistakes-clearwater-river",
  },
  {
    name: "Unforgettable Outdoor Adventures at Grandview Getaway",
    slug: "outdoor-adventures-grandview-getaway",
  },
  {
    name: "Best Outdoor Getaways in Idaho | Romantic Trips & Seasonal Retreats in Lewiston",
    slug: "best-outdoor-getaways-romantic-retreats-idaho",
  },
  {
    name: "Group Fishing Trips & Scenic Retreats | Grandview Getaway",
    slug: "group-fishing-trips",
  },
];

const Blog = () => {
  return (
    <Layout>
      <Slider />
      <Container maxWidth="lg" sx={{ padding: "2rem 0" }}>
        <Typography variant="h4" gutterBottom>
          Blog Articles
        </Typography>
        <List>
          {pages.map((page, index) => (
            <ListItem
              key={index}
              button
              component="a"
              href={`/blog/${page.slug}`}
              sx={{
                padding: "1rem",
                border: "1px solid #ddd",
                borderRadius: "8px",
                marginBottom: "1rem",
                "&:hover": { backgroundColor: "#f9f9f9" },
              }}
            >
              <ListItemText primary={page.name} />
            </ListItem>
          ))}
        </List>
      </Container>
    </Layout>
  );
};

export default Blog;