import React from "react";
import { Box, Typography, Container, Button, Grid } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import Slider from "../Home/Slider";
import { Helmet } from "react-helmet-async";

const GroupFishingTripsPage = () => {
    return (
        <>
            <Helmet>
                <title>Group Fishing Trips & Scenic Retreats | Grandview Getaway</title>
                <meta
                    name="description"
                    content="Plan your next adventure with Grandview Getaway! Offering group fishing trips in Orofino, scenic hikes near Grandview Getaway, and steelhead fishing on the Clearwater River. Explore best fishing charters, seasonal retreats in Lewiston, and unforgettable winter getaways in Lewiston."
                />
            </Helmet>
            <Layout>
                <Slider />
                <Container maxWidth="lg">
                    {/* Blog Header */}
                    <Box mt={4}>
                        <Typography variant="h3" gutterBottom>
                            Explore the Best Outdoor Adventures with Grandview Getaway
                        </Typography>
                        <Typography variant="body1" paragraph>
                            If you're seeking a place to relax, reconnect with nature, and experience adventure, <a href="https://grandviewgetaway.com/">Grandview Getaway</a> is the perfect destination. Nestled in a stunning location, our getaway offers everything from <b>group fishing trips in Orofino</b> to breathtaking <b>scenic hikes near Grandview Getaway.</b> Whether you're a seasoned angler or simply looking for an outdoor escape, we provide a variety of activities to ensure your stay is unforgettable.
                        </Typography>
                    </Box>

                    {/* Why Choose Section */}
                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Why Choose Grandview Getaway?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            At <a href="https://grandviewgetaway.com/">Grandview Getaway</a>, we specialize in creating memorable outdoor experiences. From fishing enthusiasts to hikers, our location offers a wide range of activities to suit all interests. Our proximity to the Clearwater River and scenic hiking trails makes us an ideal retreat for nature lovers.

                        </Typography>
                    </Box>

                    {/* Signature Experiences Section */}
                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Our Signature Experiences
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body1">
                                    <a href="https://grandviewgetaway.com/">
                                        <strong>Group Fishing Trips in Orofino:</strong>
                                    </a>
                                    Gather your friends or family and embark on a group fishing trip in Orofino. Known for its world-class fishing, Orofino is the perfect place to reel in your catch. Whether you’re after steelhead fishing on the Clearwater River or simply enjoying the peaceful surroundings, our expert guides ensure you have a successful and relaxing experience.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <a href="https://grandviewgetaway.com/">
                                        <strong>Scenic Hikes Near Grandview Getaway:</strong>
                                    </a>
                                    For those who love to explore the great outdoors on foot, there are numerous scenic hikes near Grandview Getaway. Wander through lush forests, traverse rugged terrain, and take in the breathtaking views of the surrounding mountains and rivers. Whether you're an experienced hiker or a beginner, our trails offer something for everyone.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <a href="https://grandviewgetaway.com/">

                                        <strong>Steelhead Fishing Clearwater River:</strong>
                                    </a>

                                    The Clearwater River is renowned for its steelhead fishing, offering an unparalleled fishing experience. Whether you prefer fly fishing or using traditional gear, you’ll be able to catch these magnificent fish while surrounded by the beauty of the river.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <a href="https://grandviewgetaway.com/">

                                        <strong>Best Fishing Charters:</strong>
                                    </a>
                                    Our best fishing charters are led by experienced captains who know the waterways inside and out. If you’re looking for an expert-guided fishing expedition, we provide the perfect charters to take you to prime fishing spots and help you catch that trophy fish.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <a href="https://grandviewgetaway.com/">

                                        <strong> Seasonal Retreats in Lewiston:</strong>
                                    </a>
                                    Whether you’re looking for a summer fishing getaway or a winter retreat, our seasonal retreats in Lewiston provide the perfect escape. Experience the best of each season with outdoor activities, cozy accommodations, and a welcoming atmosphere.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <a href="https://grandviewgetaway.com/">

                                        <strong>Winter Getaways in Lewiston:</strong>
                                    </a>
                                    If you’re planning a winter getaway in Lewiston, there’s no better place than Grandview Getaway. Enjoy snow-covered landscapes, winter fishing, and the tranquility of the season. Our cozy accommodations make it the perfect spot to relax after a day of outdoor adventures.
                                </Typography>
                            </li>
                        </ul>
                    </Box>

                    {/* Seasonal Retreats Section */}
                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Experience Nature at Its Best
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Whether you’re after an exhilarating group fishing trip, a peaceful scenic hike, or a cozy winter getaway, Grandview Getaway is the place to be. Our stunning location in Lewiston, combined with exceptional outdoor activities like steelhead fishing and expert-guided fishing charters, ensures a one-of-a-kind experience.
                        </Typography>

                    </Box>

                    {/* Closing Section */}
                    <Box mt={4} textAlign="center">
                        <Typography variant="h5" gutterBottom>
                            Book Your Adventure Today
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Ready to embark on your next outdoor adventure? Visit <a href="https://grandviewgetaway.com/">Grandview Getaway</a> and explore our range of activities, from group fishing trips in Orofino to scenic hikes and winter getaways. Let us help you create lasting memories in one of the most beautiful regions around!
                        </Typography>

                        <a
                            class="button-2" href='/plan'
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginBottom: '30px' }}
                        >
                            Start Planning Today
                        </a>

                    </Box>
                </Container>
            </Layout>
        </>
    );
};

export default GroupFishingTripsPage;
