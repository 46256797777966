import React from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Layout from "../../components/Layout/Layout";
import Slider from "../Home/Slider";
import { Helmet } from "react-helmet-async";

const OutdoorGetawaysPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Best Outdoor Getaways in Idaho | Romantic Trips & Seasonal Retreats in Lewiston
        </title>
        <meta name="description" content="Discover the best places for outdoor getaways in Idaho, romantic trips for couples, and seasonal retreats in Lewiston. Plan your perfect escape with Grandview Getaway!"></meta>
      </Helmet>
      <Layout>
        <Slider />
        <Container maxWidth="lg" sx={{ padding: "2rem 0" }}>
          {/* Meta Information */}

          <Box component="section" sx={{ marginBottom: "2rem" }}>
            <Typography variant="h5" gutterBottom>
              Best Places for Outdoor Getaways in Idaho
            </Typography>
            <Typography>
              Idaho’s natural beauty is unparalleled, with lush forests,
              crystal-clear lakes, and scenic mountains that provide the perfect
              backdrop for any outdoor adventure. Here are a few must-visit spots:
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Hells Canyon National Recreation Area"
                  secondary="As North America’s deepest river gorge, this stunning destination offers hiking trails, river rafting, and awe-inspiring views for the ultimate outdoor experience."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Coeur d'Alene Lake"
                  secondary="Known for its sparkling waters and tranquil atmosphere, this lake is perfect for boating, fishing, and soaking in Idaho’s pristine nature."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Nez Perce-Clearwater National Forest"
                  secondary="A paradise for outdoor enthusiasts, this forest offers endless trails for hiking, horseback riding, and camping under the stars."
                />
              </ListItem>
            </List>
            <Typography>
              After exploring these breathtaking locations, return to Grandview
              Getaway for a relaxing stay, complete with all the comforts of home.
            </Typography>
          </Box>

          {/* Section: Romantic Trips for Couples */}
          <Box component="section" sx={{ marginBottom: "2rem" }}>
            <Typography variant="h5" gutterBottom>
              Romantic Trips for Couples
            </Typography>
            <Typography>
              Whether you're celebrating an anniversary or simply need some quality
              time together, Idaho provides plenty of opportunities for romance.
              Grandview Getaway serves as the perfect base for couples looking to
              unwind.
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Couples' Hiking Adventures"
                  secondary="Explore scenic trails hand-in-hand and marvel at Idaho’s natural wonders. Don’t miss sunset views at the Clearwater River, a picture-perfect romantic moment."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Wine Tasting Tours"
                  secondary="Visit nearby wineries to indulge in local flavors and enjoy intimate moments surrounded by beautiful vineyards."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Cozy Evenings at Grandview Getaway"
                  secondary="After a day of adventure, relax with your loved one in a warm, comfortable setting. Light a fire, share stories, and reconnect in a cozy retreat designed for couples."
                />
              </ListItem>
            </List>
          </Box>

          {/* Section: Seasonal Retreats in Lewiston */}
          <Box component="section" sx={{ marginBottom: "2rem" }}>
            <Typography variant="h5" gutterBottom>
              Seasonal Retreats in Lewiston
            </Typography>
            <Typography>
              Lewiston offers year-round activities that cater to all seasons,
              ensuring your retreat is as memorable as it is relaxing.
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Spring and Summer"
                  secondary="Experience vibrant greenery and outdoor fun, from kayaking on the Snake River to fishing in nearby lakes. Springtime in Lewiston is perfect for picnics and scenic drives."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Fall"
                  secondary="Witness the stunning transformation of Idaho’s foliage. Go apple picking or take a leisurely walk through Lewiston’s picturesque parks."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Winter"
                  secondary="Cozy up during the colder months with indoor activities like visiting local breweries or museums. Snowshoeing and winter hikes are also popular for outdoor enthusiasts."
                />
              </ListItem>
            </List>
            <Typography>
              At Grandview Getaway, we provide the perfect environment to unwind
              after seasonal adventures. Whether it’s a weekend retreat or a longer
              escape, our accommodations are tailored to meet your needs.
            </Typography>
          </Box>

          {/* Section: Call to Action */}
          <Box component="section" sx={{ marginTop: "2rem" }}>
            <Typography variant="h5" gutterBottom>
              Plan Your Dream Getaway with Grandview
            </Typography>
            <Typography>
              At Grandview Getaway, we specialize in creating the perfect escapes
              for couples, adventurers, and anyone seeking tranquility. From
              romantic evenings to action-packed outdoor adventures, our
              accommodations provide a home away from home in the heart of Idaho’s
              natural beauty.
            </Typography>
            <Typography>
              Explore more about the best places for outdoor getaways in Idaho,
              romantic trips for couples, and seasonal retreats in Lewiston by
              visiting Grandview Getaway.
            </Typography>
            <Typography>
              Your next adventure awaits—start planning today!
            </Typography>
          </Box>
        </Container>
      </Layout>
    </>
  );
};

export default OutdoorGetawaysPage;
