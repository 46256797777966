import React from "react";
import { Container, Box, Typography, Card, CardContent, Divider } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import Slider from "../Home/Slider";

const Unforgettable = () => {
    return (
        <Layout>
            <Slider />
            <Container maxWidth="lg" sx={{ marginTop: 4 }}>
                {/* Blog Header */}
                <Typography variant="h2" component="h1" gutterBottom>
                    Unforgettable Fly Fishing Adventures: Tips for Clearwater River Trips
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" paragraph>
                    Embarking on fly fishing trips at Clearwater River offers a unique blend of serene landscapes and thrilling angling experiences. This majestic river, known for its crystal-clear waters and diverse fish population, is a paradise for fly fishing enthusiasts.
                </Typography>

                {/* Blog Content */}
                {/* Section 1 */}
                <Box mb={4}>
                    <Typography variant="h4" gutterBottom>
                        1. Timing is Everything
                    </Typography>
                    <Typography paragraph>
                        The Clearwater River’s fishing season typically peaks in late spring and early summer. However, to enjoy a less crowded experience, try visiting during the early morning or late evening hours. Not only will you encounter fewer anglers, but the fish are often more active at these times, making for a fruitful outing.
                    </Typography>
                </Box>
                <Divider />

                {/* Section 2 */}
                <Box mb={4} mt={4}>
                    <Typography variant="h4" gutterBottom>
                        2. Choose the Right Fly
                    </Typography>
                    <Typography paragraph>
                        Selecting the appropriate fly can make or break your trip. Experiment with a variety of patterns, such as nymphs, dry flies, and streamers, to find what works best on the day of your trip. Consider bringing along some local patterns that mimic the river’s natural food sources, like mayflies or caddisflies, for a competitive edge.
                    </Typography>
                </Box>
                <Divider />

                {/* Section 3 */}
                <Box mb={4} mt={4}>
                    <Typography variant="h4" gutterBottom>
                        3. Utilize a Float Tube
                    </Typography>
                    <Typography paragraph>
                        For an out-of-the-box approach, try using a float tube to navigate the river. This allows you to access hard-to-reach spots while providing a unique perspective on the water. Float tubing not only enhances your fishing experience but also adds an element of adventure, as you can easily maneuver through the river's twists and turns.
                    </Typography>
                </Box>
                <Divider />

                {/* Section 4 */}
                <Box mb={4} mt={4}>
                    <Typography variant="h4" gutterBottom>
                        4. Pack Essential Gear
                    </Typography>
                    <Typography paragraph>
                        To ensure a successful trip, pack the following essential gear:
                    </Typography>
                    <ul>
                        <li>Rod and Reel: Opt for a 5- or 6-weight rod, which offers versatility for various fish sizes.</li>
                        <li>Waders: Lightweight, breathable waders will keep you comfortable while keeping you dry.</li>
                        <li>Fly Box: Organize your flies in a waterproof box to protect them from the elements.</li>
                        <li>Net: A landing net helps you catch and release fish safely, promoting conservation efforts.</li>
                    </ul>
                </Box>
                <Divider />

                {/* Section 5 */}
                <Box mb={4} mt={4}>
                    <Typography variant="h4" gutterBottom>
                        5. Document Your Journey
                    </Typography>
                    <Typography paragraph>
                        Finally, don't forget to capture the beauty of your adventure. Use a waterproof camera or smartphone to take photos of your catches, surroundings, and memorable moments. Sharing your experiences not only inspires others but also creates lasting memories of your fly fishing trips to Clearwater River.
                    </Typography>
                </Box>
            </Container>
        </Layout>
    );
};

export default Unforgettable;
