import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import resort1 from "../Assets/images/01.png";
import resort4 from "../Assets/images/reso4.png";
import back from "../Assets/images/backHome.png";
function GridSection() {
  const data = [
    {
      img: resort1,
      head: "Home Rental 3 bed 2 bath",
      para: `  <p>This contemporary yet modern 3-bedroom, 2-bathroom home in Lenore boasts an open-concept design, creating a warm and inviting atmosphere that immediately puts you at ease. The moment you step inside, you'll feel the stress of everyday life melt away as you immerse yourself in the laid-back vibe that defines Grandview Getaway. With a 65" smart TV in the living room and a 50" in the master, you can stream any movie or show on your favorite streaming network. High-speed WiFi is available throughout the home, making it a convenient choice among Lewiston vacation rentals. You’ll enjoy a great night’s sleep on the brand-new 14-inch memory foam mattresses—one king bed in the master and two queens in the second and third bedrooms. All rooms have blackout shades for your comfort.</p>
      <p>Whether you're looking for cheap getaways for couples or planning romantic vacations in Lewiston, Grandview Getaway is the perfect spot to relax and reconnect.</p>
      <p>With its spectacular vistas, this home is a haven for nature lovers and outdoor enthusiasts alike. The spacious deck provides the perfect vantage point to soak in the beauty of the surrounding landscape. Have your cup of Joe overlooking the soft flowing Clearwater River in the morning, and cook your "catch of the day" on the Traeger smoker. It's the perfect place to enjoy dinner while watching the stunning sunsets paint the sky with hues of orange and pink, creating unforgettable memories that will stay with you long after your stay. So, pack your bags and experience the magic of Idaho. Book Grandview Getaway now and treat yourself to a vacation filled with relaxation, adventure, and cherished moments that will last a lifetime. Your dream vacation in Lenore, Idaho, awaits!</p>
      `,
    },
    {
      img: resort4,
      head: "Seasonal Beauty:",
      para: `<p>Immerse yourself in the stunning year-round beauty at Grandview Getaway. Take in the spectacular views, gather by the fire pit, and enjoy delightful seasonal experiences with loved ones. Fall brings a feast for the senses, with vibrant colors of turning leaves and crisp air invigorating your spirit. Spring hikes provide exercise and an opportunity to connect with nature. In winter, watch the snowline creep down the canyon toward the river. Gather by the fire pit, roasting s'mores, sharing stories and laughter, and fostering cherished memories with family and friends.</p>
      <p>The summer season offers a perfect chance to create more memories as you cool off in the Clearwater River on a hot day. Year-round, unique moments allow you to appreciate the beauty of nature's changing landscape and savor time with loved ones.</p>
      <p>For those seeking adventure, the area is renowned for steelhead fishing Clearwater River, and there are plenty of options for the best fishing charters and bass fishing trips in Clearwater River. This is truly a scenic getaway in Lewiston that has something for everyone.</p>
      `,
    },
  ];
  return (
    <>
      <Box sx={style.main}>
        <Container sx={style.container}>
          <Box>
            {data.map((item, index) => {
              return (
                <Grid
                  container
                  justifyContent="space-between"
                  columnSpacing={4}
                  paddingY={"40px"}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    order={index % 2 === 0 ? 1 : 2}
                    marginY="2rem"
                  >
                    <Box sx={style.box}>
                      <a
                        style={{ display: "flex", alignSelf: "center" }}
                        href="https://www.airbnb.com/rooms/837244690525037242?guests=1&amp;adults=1&amp;s=66&amp;source=embed_widget"
                      >
                        <img
                          style={{
                            width: "100%",
                            // height: "432px",
                          }}
                          alt="oops"
                          src={item.img}
                        />
                      </a>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12} order={index % 2 === 0 ? 2 : 1}>
                    <Box sx={style.box1}>
                      <Typography sx={style.heading}>{item.head}</Typography>
                      <Typography
                        sx={style.Para}
                        dangerouslySetInnerHTML={{ __html: item.para }}
                      />
                      {/* <Box>
                        <a href="https://www.airbnb.com/rooms/837244690525037242?guests=1&amp;adults=1&amp;s=66&amp;source=embed_widget">
                          <Button variant="outlined" sx={style.btn}>
                            View Details
                          </Button>
                        </a>
                      </Box> */}
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default GridSection;
const style = {
  main: {
    background: `url(${back}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",

    color: "white",
    paddingY: "3rem",
  },
  container: {
    maxWidth: { lg: "1200px", xl: "1500px" },
  },
  box: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignSelf: "center",
    justifyContent: { md: "start", xs: "center" },
  },
  box1: {
    width: "100%",
    height: "100%",

    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  heading: {
    color: "white",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Poppins",
  },
  Para: {
    color: "white",
    fontSize: "16px",
    fontFamily: "Poppins",
    width: "85%",
  },
  btn: {
    backgroundColor: "#0082CA",
    color: "white",
    paddingX: "15px",
    borderRadius: "5px",
    fontWeight: 600,
    fontSize: "1.1rem",
    paddingY: "10px",
    ":hover": {
      backgroundColor: "white",
      color: "#0082CA",
    },
  },
};
